<template>
  <a-modal
    title="公司"
    :width="modalWidth"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <template slot="footer">
      <a-button key="back" @click="handleCancel">
        取消
      </a-button>
      <a-button key="submit" :loading="loading" type="primary" @click="handleOk">
        确定
      </a-button>
    </template>
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-form-item label="公司名称">
          <a-input
            :autoFocus="true"
            placeholder="请输入公司名称"
            v-decorator="['name', { rules: [{ required: true, message: '请输入公司名称' }, { min: 2, message: '公司名称不能少于2个字' }] }]"
            allow-clear
          ></a-input>
        </a-form-item>
        <a-form-item label="企业代码">
          <a-input allow-clear placeholder="请输入企业代码" v-decorator="['code', { rules: [{ required: true, message: '请输入企业代码' }, { validator: codeValidator }] }]" />
        </a-form-item>
        <a-form-item label="所在地区">
          <a-cascader
            allow-clear
            v-decorator="['area', { rules: [{ required: true, message: '请输入所在地区' }] }]"
            :fieldNames="cityField"
            placeholder="请选择所在地区"
            :options="options"
          />
        </a-form-item>
        <a-form-item label="详细地址">
          <a-input allow-clear placeholder="请输入详细地址" v-decorator="['address', { rules: [{ required: true, message: '请输入详细地址' }] }]" />
        </a-form-item>
        <a-form-item label="公司全称">
          <a-input
            placeholder="请输入公司全称"
            v-decorator="['fullName', { rules: [{ message: '请输入公司全称' }, { min: 2, message: '公司全称不能少于2个字' }] }]"
            allow-clear
          ></a-input>
        </a-form-item>
        <a-form-item label="公司银行卡号">
          <a-input
            placeholder="请输入公司银行卡号"
            v-decorator="['bankCode', { rules: [{ message: '请输入公司银行卡号' }] }]"
            allow-clear
          ></a-input>
        </a-form-item>
        <a-form-item label="开户行">
          <a-input
            placeholder="请输入开户行"
            v-decorator="['bankName', { rules: [{ message: '请输入开户行' }] }]"
            allow-clear
          ></a-input>
        </a-form-item>
        <a-form-item label="合同上传" extra="支持扩展名：.rar .zip .doc .docx .pdf .jpg...">
          <upload-oss :media="media" @emitFiles="getFileList"></upload-oss>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>

import { companyGet, companyAdd, companyPatch, putCompanyContract } from '@/api/company'
import { UploadOss } from '@/components'
import { uploadOssBatch } from '@/utils/upload'
import { THUMBNAIL_ORIGIN, THUMBNAIL_VEDIOCOVER } from '@/common/const'
import { codeValidator } from '@/utils/util'
export default {
  components: {
    UploadOss
  },
  data() {
    return {
      noticeTitle: '公司管理',
      fields: ['name', 'code', 'area', 'address', 'fullName', 'bankCode', 'bankName'],
      visible: false,
      loading: false,
      media: [],
      id: null,
      model: null,
      form: this.$form.createForm(this)
    }
  },
  created() {
    this.formValidator(this.fields)
  },
  computed: {
    options() {
      const city = this.defaultCityOptions
      if (this.$store.state.city.city) {
        return this.$store.state.city.city
      } else {
        return city
      }
    },
    host() {
      return this.$store.state.oss.host
    }
  },
  methods: {
    codeValidator,
    reload() {
      this.$emit('reload')
    },
    showModal(record = null) {
      this.loading = false
      this.model = null
      this.id = null
      this.form.resetFields()
      if (record) {
        this.id = record.id
        this.fetchInfo(record)
      } else {
        this.visible = true
      }
    },
    getFileList(data) {
      this.media = data
    },
    handleOk() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          this.loading = true
          const area = values.area
          const param = { ...values, province: area[0], city: area[1], district: area[2], street: area[3] }
          delete param.area
          if (this.model) {
            this.patchInfo(this.id, param)
          } else {
            this.newInfo(param)
          }
        } else {
          this.loading = false
        }
      })
    },
    handleCancel() {
      this.visible = false
    },
    fetchInfo(record) {
      this.visible = true
      this.loading = true
      
      this.media = record.contractPhotos && record.contractPhotos.map(item => {
        const file = {
          uid: item.id,
          name: item.path.slice(item.path.lastIndexOf('/') + 1),
          status: 'done',
          path: item.path,
          sort: item.sort
        }
        const path = `${this.host}${item.path}`
        console.log('path', path)
        if (item.type === 'video/mp4') {
          file.url = `${path}${THUMBNAIL_VEDIOCOVER}`
          file.src = path
        } else {
          file.url = `${path}${THUMBNAIL_ORIGIN}`
        }
        return file
      });
      companyGet(this.id)
        .then(res => {
          this.model = res
          this.model.area = [res.province, res.city, res.district, res.street]
        })
        .finally(() => {
          this.loading = false
        })
    },
    newInfo(param) {
      const { media } = this
      setTimeout(() => {
        if (this.visible) {
          companyAdd(param)
            .then(res => {
              if (res.id) {
                 this.fileListUpload(res.id, media, '新建成功')
                // this.visible = false
                // this.$emit('reload')
                // this.$notification.success({ message: this.noticeTitle, description: '新建成功' })
              } else {
                this.loading = false
              }
            })
            .catch(() => {
              this.loading = false
            })
        }
      }, this.asyncTime)
    },
    patchInfo(id, param) {
      const { media } = this
      setTimeout(() => {
        if (this.visible) {
          companyPatch(id, param)
            .then(res => {
              if (res.id) {
                // this.visible = false
                // this.$emit('reload')
                // this.$notification.success({ message: this.noticeTitle, description: '修改成功' })
                this.fileListUpload(id, media, '修改成功')
              } else {
                this.loading = false
              }
            })
            .catch(() => {
              this.loading = false
            })
        }
      }, this.asyncTime)
    },
    fileListUpload(id, mediaArr = [], msg = '成功', index = 0) {
      const mediaList = mediaArr.map(item => {
        if (item.originFileObj === undefined) {
          return item
        } else {
          return item.originFileObj
        }
      })
      uploadOssBatch(mediaList, '/contracts/img/' + id).then(mediaRes => {
        console.log(mediaRes, 'mediaRes')
        if (mediaRes) {
          const len = mediaRes.length
          const urls = mediaRes
            .map(item => {
              if (item._links) {
                return item._links.self.href
              } else {
                return this.serial + '/rest/medias/' + item.uid
              }
            })
            .join('\n')
          putCompanyContract(id, urls).then(res2 => {
            if (len === 0 || index + 1 === mediaArr.length) {
              this.visible = false
              this.loading = false
              this.reload()
              this.$notification.success({
                message: this.noticeTitle,
                description: msg
              })
            } else {
              this.fileListUpload(id, mediaArr, msg, index + 1)
            }
          })
        }
      })
    }
  }
}
</script>
