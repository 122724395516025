export const columns = [
    {
        title: '公司名称',
        dataIndex: 'name',
        ellipsis: true
    },
    {
        title: '企业代码',
        dataIndex: 'code'
    },
    {
        title: '所在地区',
        dataIndex: 'area',
        ellipsis: true
    },
    {
        title: '详细地址',
        dataIndex: 'address',
        ellipsis: true
    },
    {
        title: '创建时间',
        dataIndex: 'createTime',
        ellipsis: true,
        sorter: true,
        scopedSlots: { customRender: 'createTime' }
    },
    {
        title: '操作',
        dataIndex: 'action',
        fixed: 'right',
        scopedSlots: { customRender: 'action' }
    }
]
