<template>
  <a-drawer
    title="公司详情"
    placement="right"
    :width="drawerWidth"
    class="myDescription"
    :visible="visible"
    @close="handleCancel"
  >
    <a-card :bordered="false">
      <a-spin :spinning="loading">
        <a-descriptions title="基本信息">
          <a-descriptions-item label="公司名称" span="3">{{ model.name | textFilter }}</a-descriptions-item>
          <a-descriptions-item label="公司全称" span="3">{{ model.fullName | textFilter }}</a-descriptions-item>
          <a-descriptions-item label="所在地区" span="3">{{
            model.area ? model.area.join('-') : '暂无信息'
          }}</a-descriptions-item>
          <a-descriptions-item label="企业代码" span="3">{{
            model.code | textFilter
          }}</a-descriptions-item>
          <a-descriptions-item label="详细地址" span="3">{{
            model.address | textFilter
          }}</a-descriptions-item>
          <a-descriptions-item label="创建时间" span="3">{{
            model.createTime | textFilter
          }}</a-descriptions-item>
        </a-descriptions>
        <a-table :rowKey="record => record.id" :columns="columns" :data-source="list"></a-table>
      </a-spin>
    </a-card>
  </a-drawer>
</template>

<script>
import { companyGet } from '@/api/company'
import { shopQuery } from '@/api/shop'
export default {
  data() {
    return {
      noticeTitle: '公司管理',
      visible: false,
      loading: false,
      id: null,
      model: {},
      list: [],
      form: this.$form.createForm(this),
      columns: [
        {
          title: '门店',
          dataIndex: 'company.name'
        },
        {
          title: '门店编码',
          dataIndex: 'company.code'
        }
      ]
    }
  },
  created() {},
  methods: {
    showModal(record) {
      this.visible = true
      this.id = record.id
      this.fetchData()
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.model = {}
    },
    fetchData() {
      this.loading = true
      companyGet(this.id).then(res => {
        if (res.id) {
          this.model = res
          this.model.area = [res.province, res.city, res.district, res.street]
          this.listFetch()
        }
      })
    },
    listFetch() {
      const param = {
        companyId: this.id,
        ...this.defaultPagination
      }
      shopQuery(param)
        .then(res => {
          if (res.success) {
            this.list = res.data.content
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
